import $ from 'jquery';
import exportBtns from './util/export-buttons';

// Setup Table
export const setupTable = () => {
	return $('#vue-datatable').DataTable({
		buttons: [ ...exportBtns({ 0: 'Status' }) ],
		dom: 'Bfrtip',
		pageLength: 250,
		order: [ [ 1, 'asc' ] ],
		columnDefs: [
			{
				targets: 0,
				width: '200px',
				orderable: true
			},
			{
				targets: 1,
				orderable: true
			},
			{
				targets: 2,
				orderable: true
			},
			{
				targets: 3,
				orderable: true
			}
		],
		scrollX: true
	});
};
