 <template>
  <CardHeader
    :padding="true"
    :back="!loading.is"
    :headerClass="loading.color"
    @back="redirect(true, true)"
  >
    <template #topheader>
      <div v-text="loading.text" />
    </template>
    <template>
      <div class="bulk-warpper">
        <!-- Table -->
        <VueDatatable
          ref="myTable"
          :setupTable="setupTable"
          :notExpandAll="true"
          customClass="display nowrap"
        >
          <!-- Actions -->
          <template #head-actions>
            <AddNote :ordersId="orders.map((o) => o.id)" />
          </template>
          <!-- Heading -->
          <template #thead>
            <tr class="no-arrow-sorting">
              <th class="status-col">
                <b-form-select
                  style="width: 100%"
                  v-model="search.status"
                  :options="statusOptions"
                  @change="updateStatus"
                  size="sm"
                />
              </th>
              <th class="id-col-bulk" style="width: 40px">ID</th>
              <th>IMEI</th>
              <th>Result</th>
            </tr>
          </template>
          <!-- Table Body -->
          <template #body>
            <tbody>
              <tr v-for="(order, i) in orders" :key="`al${i}`">
                <td v-for="(orderKey, i) in allowKeysHeading" :key="`h${i}`">
                  <!-- in STATUS column -->
                  <div v-if="orderKey == 'status'" class="info-status">
                    <b-spinner
                      v-if="
                        order[orderKey] == 'PENDING' ||
                        order[orderKey] == 'PROCESS'
                      "
                      class="mr-1"
                      :variant="statusColors[order[orderKey]]"
                      small
                    />
                    <b-badge
                      pill
                      :variant="statusColors[order[orderKey]]"
                      v-text="order[orderKey]"
                    />
                  </div>
                  <!-- Result column -->
                  <div
                    v-else-if="orderKey == 'result'"
                    v-html="order[orderKey]"
                  />
                  <!-- Default column -->
                  <span v-else>{{ order[orderKey] }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </VueDatatable>
      </div>
    </template>
  </CardHeader>
</template>

<script>
import VueDatatable from "@/components/util/vue-datatable/Index.vue";
import { setupTable } from "@/datatable/bulk.datatable.js";
import { statusColors } from "@/global/variables";
import { statusOptions } from "@/global/variables.js";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import CardHeader from "@/components/UI/CardHeader.vue";
import { mapState, mapMutations } from "vuex";
import { convertHTMLEntity } from "@/global/functions.js";
import AddNote from "@/components/helpers/AddNote.vue";

export default {
  data: () => ({
    dataTable: null,
    setupTable,
    search: {
      status: null,
    },
    allowKeysHeading: ["status", "id", "imei", "result"],
    orders: [],
    statusOptions,
    statusColors,
  }),
  computed: {
    ...mapState({ user: "user" }),
    userID() {
      return this.user?.user.id;
    },
    params() {
      return this.$route.params;
    },
    myTable() {
      return this.dataTable || {};
    },
    loading() {
      const isLoading = this.orders?.find(
        (o) => o.status == "PROCESS" || o.status == "PENDING"
      );
      return {
        is: isLoading,
        color: isLoading ? "bg-warning" : "bg-primary",
        text: isLoading ? "Processing.." : "Completed",
      };
    },
  },
  created() {
    const { service, imei, orders } = this.params;
    if (!imei || !service || !orders)
      return this.$router.push({ name: "imei-check" });
    this.orders = orders;
    // Examples displaying Data
    // this.orders = new Array(20).fill(0).map(() => ({
    //   id: "1",
    //   status: "test displaying",
    //   imei: "1213412414124",
    //   result:
    //     "Lorem Ipsum is simply dummy textLorem Ipsum is simply dummy text </br> Lorem Ipsum is simply dummy textLorem Ipsum </br> is simply dummy textLorem Ipsum is simply dummy textLorem <br /> Ipsum is simply dummy textLorem Ipsum is si",
    // }));
  },
  mounted() {
    // Init datatable
    this.dataTable = this.$refs.myTable;
    // Start listen for channel - WEBSOCKET
    this.$pusher
      .subscribe(`OrderChannel${this.userID}`)
      .bind("OrderProcess", (e) => {
        this.orders = this.orders.map((o, i) => {
          if (+e.orderID == o.id && e.status == "SUCCESS")
            this.HANDLE_CREDIT(-o.credit);
          // Re-Update datatable
          setTimeout(() => {
            this.dataTable.access().row(i).invalidate();
            // this.dataTable.access().columns.adjust().draw();
          }, 100);
          return +e.orderID == o.id
            ? { ...o, ...e, result: convertHTMLEntity(e.result) }
            : o;
        });
      });
  },
  destroyed() {
    this.$pusher.unsubscribe("OrderChannel");
  },
  methods: {
    ...mapMutations(["HANDLE_CREDIT"]),
    updateStatus(v) {
      this.dataTable
        ?.access()
        .columns(0)
        .search(v || "", true, false)
        .draw();
    },
    redirect(imei = false, service = true) {
      const params = {
        imei: imei ? this.params.imei : null,
        service: service ? this.params.service : null,
        bulk: true,
      };
      this.$router.push({ name: "imei-check", params });
    },
  },
  components: {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    CardHeader,
    VueDatatable,
    AddNote,
  },
};
</script>
 
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.bulk-warpper {
  .info-status {
    display: flex;
    align-items: center;
  }
  .status-col {
    padding-left: 0 !important;
  }
  .sorting_disabled.sorting_asc.id-col-bulk {
    padding: 10px 18px !important;
    padding-left: 0 !important;
  }
  .card-body {
    padding: 0;
  }
  // table
  #vue-datatable {
    white-space: nowrap;
  }
}
</style>