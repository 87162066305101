<template>
  <div>
    <!-- button -->
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-center
      variant="secondary"
    >
      Add Note
    </b-button>
    <!-- modal -->
    <b-modal
      id="modal-center"
      ref="my-modal"
      modal-class="modal-primary"
      title="Add note"
      hide-footer
      centered
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="noteState"
          label="Note"
          label-for="name-input"
          invalid-feedback="Note is required, from 3 characters to 60 characters"
        >
          <b-form-input
            placeholder="Enter note for search later"
            v-model="note"
            :state="noteState"
            required
          />
        </b-form-group>
        <div class="footer-addnote">
          <b-button class="mr-1" variant="outline-primary" @click="close" pill>
            Close
          </b-button>
          <b-button
            type="submit"
            :disabled="loading"
            variant="primary"
            pill
          >
            <b-spinner class="mr-1" v-if="loading" small />
            Add
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Order from "@/services/orders.service.js";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  props: ["ordersId"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    loading: false,
    note: "",
    noteState: null,
  }),
  methods: {
    checkFormValidity() {
      const valid =
        this.$refs.form.checkValidity() &&
        this.note.length >= 3 &&
        this.note.length <= 60;
      this.noteState = valid;
      return valid;
    },
    resetModal() {
      this.note = "";
      this.noteState = null;
    },
    close() {
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) return;
      this.loading = true;
      try {
        await Order.addNote(this.ordersId, this.note);
        this.close();
      } catch {}
      this.loading = false;
    },
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
  },
};
</script>
<style lang="scss" scoped>
.footer-addnote {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>